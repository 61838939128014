// used by navigation.js and facetedFilterController.js

function moveFacetedFilters(nav) {
  const facetedHero = document.querySelector(
    'section.directory-hero.directory-hero--faceted',
  );
  const filtersForm = document.querySelector('.faceted-filters__container');
  const newHero = document.querySelector('[data-directory-new-header-hero]');
  const heroDescription = document.querySelector('[data-hero-description]');
  const subheading = document.querySelector(
    '[data-directory-header-subheading]',
  );
  if (facetedHero && filtersForm && newHero && nav) {
    const headerHeight =
      getComputedStyle(nav).getPropertyValue('--header-height');
    if (nav.classList.contains('is-sticking')) {
      nav.appendChild(filtersForm);
    } else {
      facetedHero.appendChild(newHero);
      if (subheading) facetedHero.appendChild(subheading);
      if (heroDescription) facetedHero.appendChild(heroDescription);
      facetedHero.appendChild(filtersForm);
    }
    document.body.style.setProperty('--header-height', headerHeight);
  }
}

export { moveFacetedFilters };
